<div class="spinner-overlay" *ngIf="cargando">
  <div class="spinner"></div>
</div>

<section class="Banner ubicar"  *ngIf="imagenesTipo4?.length > 0">
  <div class="row">
    <div class="col-12 p-0">
      <div id="banner" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <!-- Imagenes -->
        <div class="carousel-inner">
          <div
            class="carousel-item"
            *ngFor="let img of imagenesTipo4; let i = index"
            [class.active]="i === 0">
            <img [src]="img.url" class="d-block w-100" (click)="irAlEvento(img.eventoId)">
          </div>
        </div>
        <!-- Controles -->
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#banner"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#banner"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden"></span>
        </button>
      </div>
    </div>
  </div>
</section>



<div class=" centrarTxt pt-3">
  <h2 class="titulo-eventos">EVENTOS</h2>
  <hr class="linea-naranja2" />
</div>

<div class="contenedor-buscador">
  <div class="contenedor-flex2">
    <div class="inputs-contenedor2 margen-top">
      <input type="text" placeholder="Buscar por nombre/artista" [(ngModel)]="busqudaNombre" class="buscadores lupa buscadores-2 miniBusca ">
    </div>
    <div class="inputs-contenedor2 margen-top ">
      <select class="buscadores-2 triangulo" [(ngModel)]="idCiudad">
        <option value="-1" selected>Selecciona ciudad</option>
        <option value="{{ciudad.id}}" *ngFor="let ciudad of ciudades">{{ciudad.nombre}}</option>
      </select>
    </div>
    <div class="inputs-contenedor2 margen-top">
      <select class="buscadores-2 triangulo" [(ngModel)]="tipoEvento">
        <option value="-1" selected>Selecciona genero</option>
        <option *ngFor="let tipo of tiposEventosDisponibles" [value]="tipo">{{tipo}}</option>
      </select>

    </div>
    <div class="button-contenedor  margen-top">
      <button class="action_button2" (click)="buscar()">Buscar</button>
    </div>
  </div>
</div>



<section class="container-fluid " style="margin-top: 0%; margin-bottom: 1%; ">


  <div class="row ">

    <div class=" col-6 col-sm-6  col-md-6  pl-sm-5 pr-sm-5 pl-md-2 pr-md-2 col-lg-4 col-xl-3 contenedor8"
      *ngFor="let evento of eventos">
      <div class="hover-overlay border rounded-top imgagenperfil" style="background-color: black; width: 100%;">

        <div class="contenedor2">
          <img *ngIf="evento.soldOut" class="sold_out" src="../../assets/images/img/SOLDOUT.png" alt="">
          <a routerLink="/eventos/evento/{{evento.id}}" class="outerLink">
            <div class="oscurecer">
              <img src="{{darURL(evento)}}" *ngIf="evento.imagenes.length>0" class=" rounded-top  darkableImage " />


            </div>
            <p class="centrado2"><strong> Compra ya tus Tickets para el evento {{evento.nombre}}</strong>

            </p>
          </a>
        </div>
      </div>

      <div class="border rounded-bottom container-fluid gris contenedorabajo">

        <div class="">
          <div class="col-12 tituloEvento">
            <h2 class="titulos-1 "><strong> <a class="titulos"
                  routerLink="/eventos/evento/{{evento.id}}">{{evento?.nombre |resumir2}}</a></strong>
            </h2>
            <div class="lineaOrange"> </div>
          </div>
          <div class="espacioA">
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 39.png" alt="">
              <p class="fechas "><strong>{{darFecha(evento)}} </strong></p>
            </div>
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 37.png" alt="">
              <p class="fechas "><strong>{{ evento?.horaInicio | horas2}} </strong></p>
            </div>
            <div class="col-12  border-right fechaCiudad ">
              <img class="logoCal" src="../../assets/images/Vectores/Vectores_Mesa de trabajo 1 copia 38.png" alt="">
              <p class="fechas "><strong>{{evento?.ciudad?.nombre }} </strong></p>
            </div>

          </div>

          <div class="contenedorInfo">
            <div class=" botonVer">
              <button *ngIf="!evento.soldOut" class="action_button   inf bg-boton"
                routerLink="/eventos/evento/{{evento.id}}">Info.</button>
            </div>

          </div>

        </div>

      </div>
    </div>

    <div class="centrar-flex colorLetra" *ngIf="eventos.length==0 && cargando == false">
      <strong>Ningún Evento Encontrado</strong>
    </div>

  </div>
</section>
