<section class="container">
    <div class="row">
        
    <div class ="col-12 login  contenederoLogin">
        <mat-icon class="float-right rounded mt-2 pl-3 pr-3 botonesCerrar" mat-button mat-dialog-close> cancel_presentation</mat-icon>
        <div class="fondoBlanco rounded shadow-lg">
            <div class=" pt-4 pb-2">
                <h2 class="colorLetras"><strong>Recuperar mi contraseña</strong></h2>
                <h5><strong>Escribe tu correo electronico con el que te registrasté!</strong></h5>
            </div>
            
            <form (ngSubmit)="enviar()" class="p-4">
                <div class="mt-3  form-group">
                    
                    <input type="text" name= "username" class="rounded form-control"  placeholder="Correo" [(ngModel)]="correo" >
                </div>    
                
            
                <div class="mt-3 mb-3 ">
                    <small *ngIf ='errorMessage' class="text-danger" >{{errorMessage}}</small>
                    <br *ngIf ='errorMessage'>
                    <button class="btn botonLogin mt-3 mb-3"  type="submit">Enviar</button>
                </div>
            </form>
            
            
        </div>
    </div>
    
</div>
</section>
