<div class="container mt-2 mb-5">

    <div class="row">
        <div class="col-12 shadow-lg rounded p-4 fondoNegro">
            <mat-icon class="float-right rounded pb-2 pl-3 pr-3 botonesCerrar" mat-button mat-dialog-close>
                cancel_presentation</mat-icon>
            <div class="centrarTexto">
                <h3 class="colorLetras"><strong>REGÍSTRATE</strong></h3>
                <h5><strong>Compra tus Tickets Premium</strong></h5>
            </div>
            <hr>


            <form (ngSubmit)="!todoForm.invalid && saveUsuario()" class="mt-4" #todoForm="ngForm">
                

                    <fieldset class="form-group">

                        <input type="text" class="form-control" name="nombre" placeholder="Nombre completo" required
                            minlength="6" pattern="[a-zA-Z\s]+" [(ngModel)]="usuario.nombre" #nombre="ngModel">
                        <ng-container *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
                            <small class="colorLetras" *ngIf="nombre.errors.minlength">
                                El nombre debe tener al menos 6 caracteres.
                            </small>
                            <br>
                            <small class="colorLetras" *ngIf="nombre.errors.pattern">
                                El nombre no puede contener números.
                            </small>
                        </ng-container>

                    </fieldset>
                    <fieldset class="form-group">

                    <select class="form-control" name="tipoDocumento" required="required"
                        placeholder="Tipo de documento" [(ngModel)]="usuario.tipo_documento">
                        <option value="Tipo de Documento" disabled selected>Tipo de documento</option>
                        <option value="Cedula">Cédula</option>
                        <option value="Tarjeta de identidad">Tarjeta de Identidad</option>
                        <option value="Pasaporte">Pasaporte</option>
                        <option value="Cedula de Extranjería ">Cédula de extranjería</option>
                    </select>
                </fieldset>

                <fieldset class="form-group">
                    <input class="form-control " name="id" placeholder="Número de documento" required="required"
                        [(ngModel)]="usuario.numeroDocumento">
                </fieldset>

                <fieldset class="form-group">

                    <input class="form-control" id="bloquear2" name="idconfirmacion"
                        placeholder="Confirmar número de documento" required="required"
                        [(ngModel)]="confimarcionDocumento">
                    <small *ngIf="this.usuario.numeroDocumento!=confimarcionDocumento" class="colorLetras">Verifica el
                        número de documento</small>
                </fieldset>

                <fieldset class="form-group">

                    <input type="text" placeholder="Número de celular" class="form-control " name="celular"
                        required="required" [(ngModel)]="usuario.celular" pattern="^\d+$">

                </fieldset>

                <fieldset class="form-group">

                    <input type="email" placeholder="Correo" class="form-control " id="email" name="correo"
                        required="required" [(ngModel)]="usuario.correo" (input)="validateEmail()">
                    <small class="colorLetras">Verifica que hayas escrito bien tu correo, ahí llegarán tus
                        Tickets</small>
                </fieldset>

                <fieldset class="form-group">

                    <input type="email" id="bloquear" placeholder="Confirmar correo" class="form-control "
                        name="confirmacorreo" required="required" [(ngModel)]="confimarcionCorreo">
                    <small *ngIf="this.usuario.correo!=confimarcionCorreo" class="colorLetras">Verifica el
                        correo</small>
                </fieldset>


                <fieldset class="form-group">

                    <input type="password" placeholder="Contraseña" class="form-control " name="contrasena"
                        required="required" [(ngModel)]="usuario.contrasena">
                </fieldset>


                <div class="container-fluid">
                    <div class="row">
                        <fieldset class="form-group pl-4 col-12 col-md-4">
                            <input type="checkbox" class="form-check-input" (change)="publicidad()" id="public"
                                required="required">
                            <small class="form-check-label colorGris" for="public">Acepto que me envíen
                                publicidad</small>
                        </fieldset>

                        <fieldset class="form-group pl-4 col-12 col-md-4">
                            <input type="checkbox" class="form-check-input" (change)="aceptarTerminos()" id="public"
                                required="required">
                            <small class="form-check-label colorGris" for="public">He leído y acepto <a class="terminos"
                                    href="https://codigos.allticketscol.com/TerminosYCondiciones.html"
                                    target="_blank">Términos y Condiciones</a></small>
                        </fieldset>

                        <fieldset class="form-group pl-4 col-12 col-md-4">
                            <input type="checkbox" class="form-check-input" (change)="aceptarTratamiento()" id="public"
                                required="required">
                            <small class="form-check-label colorGris" for="public">He leído y acepto <a class="terminos"
                                    href="https://codigos.allticketscol.com/TratamientoDeDatos.html"
                                    target="_blank">Tratamiento de datos</a> </small>
                        </fieldset>

                    </div>
                </div>




                <div style="text-align: center;">
                    <button class="btn botonLogin mt-2 mb-3" type="submit">Registrarse</button>
                </div>

            </form>
        </div>
    </div>
</div>