import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {  API_URL_AUTH, API_URL_USUARIOS } from 'src/app/app.constants';
import { Md5 } from 'ts-md5';
import { Usuario } from '../usuario.model';
import { MensajeComponent } from 'src/app/mensaje/mensaje.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _usuario: Usuario;
  private _token: string;


  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router) { }


  public get usuario(): Usuario {
    if (this._usuario != null) {
      return this._usuario;
    } else if (
      this._usuario == null &&
      sessionStorage.getItem('usuario') != null
    ) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._usuario;
    }
    return new Usuario();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (this._token == null && sessionStorage.getItem('token') != null) {
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;
  }


  login(usuario: Usuario): Observable<any> {
    const urlEndPoint = API_URL_AUTH + '/oauth/token';

    const credenciales = btoa('alltickets.front' + ':' + 'l!Uq!Ujhfzyjd%Mk*a6H');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + credenciales,
    });

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario.correo);
    var md5 = new Md5();

    var contra = md5.appendStr(usuario.contrasena).end().toString();

    params.set('password', contra);

    return this.http.post<any>(urlEndPoint, params.toString(), {
      headers: httpHeaders,
    });
  }

  guardarUsuario(accessToken: string): void {
    let objeto = this.obtenerDatosDelTocken(accessToken);
    this._usuario = new Usuario();
    this._usuario.nombre = objeto.nombre;

    this._usuario.usuario = objeto.user_name;

    this._usuario.roles = objeto.authorities;

    this._usuario.numeroDocumento =objeto.cc
    

    this._usuario.roles.forEach(r=>{
      if(r=="ROLE_CLIENTE"){
        this._usuario.tipo='usuario'
      }
      else if(r=="ROLE_PROMOTOR"){
        this._usuario.tipo='promotor'
      }
    })

    if (this._usuario.tipo == 'usuario') {
      sessionStorage.setItem('usuario', this._usuario.usuario);

      sessionStorage.setItem('usuarioEntidad', JSON.stringify(this._usuario));
      this.dialog.closeAll();

    } else if (this._usuario.tipo == 'promotor') {
      this.openMensaje('<p>Nos hemos actualizado, para realizar tus ventas por favor ingresar a:  <a style="color:#ed701c;" href="https://promotores.allticketscol.com"> https://organizadores.allticketscol.com</a> </p>')
    }
    else{
      this.openMensaje('<p>Nos hemos actualizado, para realizar tus consultas por favor ingresar a:  <a style="color:#ed701c;" href="https://organizadores.allticketscol.com"> https://organizadores.allticketscol.com</a> </p>')
    }

  }

  guardarToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('token', accessToken);
    
  }

  obtenerDatosDelTocken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split('.')[1]));
    } else {
      return null;
    }
  }

  isAuthenticated(): boolean {
    let payload = this.obtenerDatosDelTocken(this.token);
    if (payload != null && payload.user_name && payload.user_name.length > 0) {
      return true;
    }
    return false;
  }

  hasRole(role: string): boolean {
    if (
      this.usuario.roles != undefined &&
      this.usuario.roles.length > 0 &&
      this.usuario.roles.includes(role)
    ) {
      return true;
    }
    return false;
  }

  logout(): void {
    this._token = null;
    this._usuario = null;

    sessionStorage.clear();
    sessionStorage.removeItem('usuarioEntidad');
    sessionStorage.removeItem('token');
    
    sessionStorage.removeItem('usuario');
    sessionStorage.removeItem('promotor');

  }

  guardarSesionEnLocalStorage(): void {
    const sessionData = {
      token: sessionStorage.getItem('token'),
      usuario: sessionStorage.getItem('usuario'),
      usuarioEntidad: sessionStorage.getItem('usuarioEntidad')
    };
    localStorage.setItem('tempSession', JSON.stringify(sessionData));
  }

  //Carga la session desde el localstoreage y luego lo elimina
  cargarSesionDesdeLocalStorage(): void {
    const tempSession = localStorage.getItem('tempSession');
    if (tempSession) {
      const sessionData = JSON.parse(tempSession);
      
      sessionStorage.setItem('token', sessionData.token);
      sessionStorage.setItem('usuario', sessionData.usuario);
      sessionStorage.setItem('usuarioEntidad', sessionData.usuarioEntidad);
      
      this._token = sessionData.token;
      this._usuario = JSON.parse(sessionData.usuarioEntidad);
      
      localStorage.removeItem('tempSession');
    }
  }

  openMensaje(mensajeT:string,openD?:string):void{
    let screenWidth = screen.width;
    let anchoDialog:string = '500px';
    let anchomax:string = '80vw';
    let altoDialog:string = '250';
    if(screenWidth<=600){
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data:{
        mensaje:mensajeT
      }
    });
  }
}